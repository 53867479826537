import ColorPallet from "./color-pallet.interface";

const pallet: ColorPallet = {
  transparent: "transparent",
  black: "#000",
  white: "#ffffff",
  whiteTransparent: [
    "#FFFFFF10",
    "#FFFFFF20",
    "#FFFFFF30",
    "#FFFFFF40",
    "#FFFFFF50",
    "#FFFFFF60",
    "#FFFFFF70",
    "#FFFFFF80",
    "#FFFFFF90",
    "#FFFFFF99",
  ],
  //Orange
  primary: [
    "#000000",
    "#190A06",
    "#32150C",
    "#4C2012",
    "#652B18",
    "#7F361E",
    "#984024",
    "#B14B2A",
    "#CB5630",
    "#E46136",
    "#FE6C3C",
    "#FE6C3C", //main color
    "#FE7A4F",
    "#FE8963",
    "#FE9876",
    "#FEA68A",
    "#FEB59D",
    "#FEC4B1",
    "#FED2C4",
    "#FEE1D8",
    "#FEF0EB",
    "#FFFFFF",
  ],

  //Blue
  secondary: [
    "#000000",
    "#060E19",
    "#0C1C32",
    "#122A4C",
    "#183865",
    "#1F477F",
    "#255598",
    "#2B63B1",
    "#3171CB",
    "#377FE4",
    "#3E8EFE",
    "#3E8EFE",
    "#5199FE",
    "#64A4FE",
    "#77AFFE",
    "#8BBBFE",
    "#9EC6FE",
    "#B1D1FE",
    "#C5DDFE",
    "#D8E8FE",
    "#EBF3FE",
    "#FFFFFF",
  ],
  secondaryTransparent: [],
  //blue-gray
  neutrals: [
    "#F0F4F8",
    "#D9E2EC",
    "#BCCCDC",
    "#9FB3C8",
    "#829AB1",
    "#627D98",
    "#486581",
    "#334E68",
    "#243B53",
    "#102A43",
  ],

  //Dark
  supportingA: [
    "#000000",
    "#060606",
    "#0C0D0D",
    "#131314",
    "#191A1B",
    "#202122",
    "#262728",
    "#2C2E2F",
    "#333436",
    "#393B3D",
    "#404244",
    "#404244",
    "#535456",
    "#666769",
    "#797A7C",
    "#8C8D8E",
    "#9FA0A1",
    "#B2B3B4",
    "#C5C6C6",
    "#D8D9D9",
    "#EBECEC",
    "#FFFFFF",
  ],

  supportingB: [],
};

export default pallet;
