export const sharedFormElementStyles = {
  //focus:outline-none focus:bg-white focus:border-gray-500"
  appearence: "none",
  display: "block",
  width: "100%",
  backgroundColor: "white",
  color: "dark",
  borderColor: "white",
  borderRadius: "4px",
  py: 3,
  px: 2,
  fontFamily: "body",

  "&:focus": {
    outline: "none",
    backgroundColor: "white",
    borderColor: "primary",
  },
};

const forms = {
  forms: {
    label: {
      fontFamily: "body",
      fontSize: ".75rem",
      fontWeight: "bold",
      textTransform: "uppercase",
      letterSpacing: 0.5,
      color: "text",
      mb: 1,
      //block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2
    },
    input: {
      ...sharedFormElementStyles,
      borderColor: "supportingALight",
    },
    select: {
      borderColor: "gray",
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t: any): string => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
    },
    textarea: {
      ...sharedFormElementStyles,
      borderColor: "supportingALight",
    },
    slider: {
      bg: "muted",
    },
    validationError: {
      fontStyle: "italic",
      fontSize: ".75rem",
      color: "red",
    },
  },
};

export default forms;
