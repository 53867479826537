const buttons = {
  buttons: {
    primary: {
      color: "#313234",
      bg: "primary",
      py: 2,
      px: 4,
      fontSize: [3, 4, 4],
      borderRadius: "5px",
      textDecoration: "none",
      //boxShadow:"0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24)",
      outline: {
        color: "primary",

        py: 2,
        px: 4,
        fontSize: [3, 4, 4],
        borderRadius: "5px",
        borderColor: "primary",
        borderStyle: "solid",
        textDecoration: "none",
        borderWidth: "2px",
      },
      small: {
        color: "#313234",
        bg: "primary",
        py: 2,
        px: 4,
        fontSize: [2],
        borderRadius: "5px",
        textDecoration: "none",
      },
    },
  },
};

export default buttons;
