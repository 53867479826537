import buttons from "./buttons";
import cards from "./cards";
import colors from "./colors";
import forms from "./forms";
import layout from "./layouts";
import spacing from "./spacing";
import typeography, {
  headingStyles,
  textStyles,
  lineHeights,
} from "./typeography";
const theme = {
  breakpoints: ["768px", "1025px", "1290px"],

  ...spacing,
  ...typeography,
  ...lineHeights,
  colors: { ...colors },

  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: 2,
    },
    ...headingStyles,
    ...textStyles,
    blockQuote: {
      display: "block",
      borderWidth: "2px 0",
      borderStyle: "solid",
      borderColor: "#eee",
      padding: "1.5em 0 0.5em",
      margin: "1.5em 0",
      position: "relative",
      "::before": {
        content: "'\\201C'",
        position: "absolute",
        top: "0em",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "#fff",
        width: "3rem",
        height: "2rem",
        font: "6em/1.08em 'PT Sans', sans-serif",
        color: "primary",
        textAlign: "center",
      },
    },
  },

  ...layout,
  ...buttons,
  ...cards,
  ...forms,
};

export default theme;
