export const fonts = {
  body: " Roboto, Helvetica, Arial, sans-serif",
  heading: "Roboto,  Montserrat, system-ui, sans-serif",
  monospace: "Menlo, monospace",
  siteTitle: "Roboto, Montserrat, system-ui, sans-serif",
  siteTitleItalics: "kinescope,cursive",
};
export const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 96];
export const fontWeights = {
  thin: 100,
  light: 300,
  body: 400,
  heading: 700,
  bold: 700,
};
export const lineHeights = {
  body: 1.5,
  heading: 1,
};
export const sharedHeadingStyles = {
  m: 0,
  color: "primary",
  fontFamily: "heading",
  lineHeight: "heading",
  fontWeight: "heading",
  letterSpacing: 1.5,
  marginBlockStart: ".5em",
};

export const headingStyles = {
  h1: {
    ...sharedHeadingStyles,
    textAlign: "center",
    fontSize: 5,
  },
  h2: {
    ...sharedHeadingStyles,

    fontSize: [2, 2, 5],
  },
  h3: {
    ...sharedHeadingStyles,
    fontSize: 3,
  },
  h4: {
    ...sharedHeadingStyles,
    fontSize: 2,
  },
  h5: {
    ...sharedHeadingStyles,
    fontSize: 1,
  },
  h6: {
    ...sharedHeadingStyles,
    fontSize: 0,
  },
};

export const textStyles = {
  a: {
    textDecoration: "none",
    color: "primary",
  },
  p: {
    color: "text",
    fontFamily: "body",
    fontWeight: "light",
    lineHeight: "body",
    fontSize: [3, 3],
    //marginBlockStart: '.5em',
    marginBlockEnd: "1.5em",
    nomargin: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: 2,
    },
  },
};

const typography = {
  fonts: { ...fonts },
  fontSizes: { ...fontSizes },
  fontWeights: { ...fontWeights },
  lineHeights: { ...lineHeights },
};

export default typography;

/*
  body: 'Roboto, Helvetica, Arial, sans-serif',
  heading: '"FS Siena", Roboto, Montserrat, system-ui, sans-serif',
  monospace: 'Menlo, monospace',
  siteTitle: "'TSXSBG+Senlot-ExtBol',Roboto, Montserrat, system-ui, sans-serif",
  siteTitleItalics: "kinescope,cursive",
*/
