import pallet from "./color-pallets/site-colors";

const colors = {
  primaryLight: pallet.primary[14],
  primary: pallet.primary[11],
  primaryDark: pallet.primary[4],
  primaryTransparent: `${pallet.primary[11]}30`,

  whiteTransparent: pallet.whiteTransparent[9],

  secondaryLight: pallet.secondary[14],
  secondary: pallet.secondary[11],
  secondaryDark: pallet.secondary[4],
  secondaryTransparent: `${pallet.secondary[8]}80`,

  supportingALight: "#45494e",
  supportingAExtraLight: "#F6F7F8",
  supportingA: pallet.supportingA[11],
  supportingADark: "#383a3c",
  supportingATransparent: `rgba(90, 92, 95, 0.90)`,

  text: "#383a3c",
  textMuted: pallet.neutrals[5],
  textLight: pallet.neutrals[2],

  light: pallet.neutrals[1],

  background: pallet.white,
  backgroundFill: pallet.neutrals[2],
};

export default colors;

export const gradients = {
  primary: (): string => {
    return `linear-gradient(to right, ${pallet.primary[2]} 10%, ${pallet.primary[1]} 100%);`;
  },
};

/*
const gradients= {
    primary: {
      backgroundImage: () => {
        return `linear-gradient(to right, ${pallet.primary[6]} 10%, ${pallet.primary[3]} 100%);`;
      },
    }
  };
*/
export const colorPallet = { ...pallet };
